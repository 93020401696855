const warehousingSystemRouter = [
  // 仓储系统
  {
    path: '/warehousingSystem/storeManagement',
    name: 'storeManagement',
    component: () =>
      import('../views/warehousingSystem/storeManagement.vue'),
    meta: {
      name: '仓库管理',
      keepAlive: true,
      parentPath: '/warehousingSystem/storeManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/addWarehouse',
    name: 'addWarehouse',
    component: () =>
      import('../views/warehousingSystem/children/addWarehouse.vue'),
    meta: {
      name: '添加仓库',
      parentPath: '/warehousingSystem/storeManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/storehouseDetails',
    name: 'storehouseDetails',
    component: () =>
      import('../views/warehousingSystem/children/storehouseDetails.vue'),
    meta: {
      name: '仓库详情',
      parentPath: '/warehousingSystem/storeManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/inventoryManagement',
    name: 'inventoryManagement',
    component: () =>
      import('../views/warehousingSystem/inventoryManagement.vue'),
    meta: {
      name: '库存管理',
      keepAlive: true,
      parentPath: '/warehousingSystem/inventoryManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/shiftingParking',
    name: 'shiftingParking',
    component: () =>
      import('../views/warehousingSystem/shiftingParking.vue'),
    meta: {
      name: '移库管理',
      keepAlive: true,
      parentPath: '/warehousingSystem/shiftingParking',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/stockDetails',
    name: 'stockDetails',
    component: () =>
      import('../views/warehousingSystem/children/stockDetails.vue'),
    meta: {
      name: '入库详情',
      parentPath: '/warehousingSystem/stockManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/takeStock',
    name: 'takeStock',
    component: () => import('../views/warehousingSystem/takeStock.vue'),
    meta: {
      name: '盘点管理',
      keepAlive: true,
      parentPath: '/warehousingSystem/takeStock',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/transferOwnership',
    name: 'transferOwnership',
    component: () =>
      import('../views/warehousingSystem/transferOwnership.vue'),
    meta: {
      name: '过户管理',
      keepAlive: true,
      parentPath: '/warehousingSystem/transferOwnership',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/warehouseManagement',
    name: 'warehouseManagement',
    component: () =>
      import('../views/warehousingSystem/warehouseManagement.vue'),
    meta: {
      name: '出库管理',
      keepAlive: true,
      parentPath: '/warehousingSystem/warehouseManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/stockManagement',
    name: 'stockManagement',
    component: () =>
      import('../views/warehousingSystem/stockManagement.vue'),
    meta: {
      name: '入库管理',
      keepAlive: true,
      parentPath: '/warehousingSystem/stockManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/stockStorage',
    name: 'stockStorage',
    component: () =>
      import('../views/warehousingSystem/children/stockStorage.vue'),
    meta: {
      name: '新增入库',
      parentPath: '/warehousingSystem/stockManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/warehouseStorage',
    name: 'warehouseStorage',
    component: () =>
      import('../views/warehousingSystem/children/warehouseStorage.vue'),
    meta: {
      name: '新增出库',
      parentPath: '/warehousingSystem/warehouseManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/inventoryDetails',
    name: 'inventoryDetails',
    component: () =>
      import('../views/warehousingSystem/children/inventoryDetails.vue'),
    meta: {
      name: '库存详情',
      parentPath: '/warehousingSystem/inventoryManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/transferOwnershipDetails',
    name: 'transferOwnershipDetails',
    component: () =>
      import('../views/warehousingSystem/children/transferOwnershipDetails.vue'),
    meta: {
      name: '过户详情',
      parentPath: '/warehousingSystem/transferOwnership',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/addTransferOwnership',
    name: 'addTransferOwnership',
    component: () =>
      import('../views/warehousingSystem/children/transferOwnershipAdd.vue'),
    meta: {
      name: '新增过户',
      parentPath: '/warehousingSystem/transferOwnership',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/addShiftingParking',
    name: 'addShiftingParking',
    component: () =>
      import('../views/warehousingSystem/children/transferOwnershipAdd.vue'),
    meta: {
      name: '新增移库',
      parentPath: '/warehousingSystem/shiftingParking',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/relocationDetails',
    name: 'shiftingParking',
    component: () =>
      import('../views/warehousingSystem/children/relocationDetails.vue'),
    meta: {
      name: '移库详情',
      parentPath: '/warehousingSystem/shiftingParking',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/transferOwnershipAdd',
    name: 'transferOwnershipAdd',
    component: () =>
      import('../views/warehousingSystem/children/transferOwnershipAdd.vue'),
    meta: {
      name: '新增盘点',
      parentPath: '/warehousingSystem/takeStock',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/checkDetails',
    name: 'takeStock',
    component: () => import('../views/warehousingSystem/children/checkDetails.vue'),
    meta: {
      name: '盘点详情',
      parentPath: '/warehousingSystem/takeStock',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/warehouseDetails',
    name: 'warehouseDetails',
    component: () =>
      import('../views/warehousingSystem/children/warehouseDetails.vue'),
    meta: {
      name: '出库详情',
      parentPath: '/warehousingSystem/warehouseManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/ladingBillManagement',
    name: 'ladingBillManagement',
    component: () =>
      import('../views//warehousingSystem/ladingBillManagement.vue'),
    meta: {
      name: '提单管理',
      keepAlive: true,
      parentPath: '/warehousingSystem/ladingBillManagement',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/ladingBillExWarehouse',
    name: 'ladingBillExWarehouse',
    component: () =>
      import('../views//warehousingSystem/children/ladingBillExWarehouse.vue'),
    meta: {
      name: '代采出库',
      parentPath: '/warehousingSystem/ladingBillExWarehouse',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/storeLadingBillExWarehouse',
    name: 'storeLadingBillExWarehouse',
    component: () =>
      import('../views//warehousingSystem/children/storeLadingBillExWarehouse.vue'),
    meta: {
      name: '商城出库',
      parentPath: '/warehousingSystem/storeLadingBillExWarehouse',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/ladingBillExWarehouseDetails',
    name: 'ladingBillExWarehouseDetails',
    component: () =>
      import('../views//warehousingSystem/children/ladingBillExWarehouseDetails.vue'),
    meta: {
      name: '代采提单详情',
      parentPath: '/warehousingSystem/ladingBillExWarehouse',
      requireAuth: true
    }
  },
  {
    path: '/warehousingSystem/storeMerchantBillDetails',
    name: 'storeMerchantBillDetails',
    component: () =>
      import('../views//warehousingSystem/children/storeMerchantBillDetails.vue'),
    meta: {
      name: '商城提单详情',
      parentPath: '/warehousingSystem/ladingBillExWarehouse',
      requireAuth: true
    }
  }
]
export default warehousingSystemRouter
