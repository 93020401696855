const enterpriseInformationRoute = [
  {
    path: '/enterpriseInformation/addInformation',
    name: 'addInformation',
    meta: {
      title: '企业信息',
      parentPath: '/enterpriseInformation/addInformation',
      requireAuth: true
    },
    component: () =>
      import('@/views/enterpriseInformation/addInformation.vue')
  },
  {
    path: '/enterpriseInformation/accountManagement',
    name: 'accountManagement',
    meta: {
      title: '账户管理',
      keepAlive: true,
      parentPath: '/enterpriseInformation/accountManagement',
      requireAuth: true
    },
    component: () =>
      import('@/views/enterpriseInformation/accountManagement.vue')
  },
  {
    path: '/enterpriseInformation/accountInformation',
    name: 'accountInformation',
    component: () => import('../views/enterpriseInformation/accountInformation.vue'),
    meta: {
      parentPath: '/enterpriseInformation/accountInformation',
      requireAuth: true,
      keepAlive: true,
      name: '账户信息'
    }
  }, {
    path: '/enterpriseInformation/contractTemplate',
    name: 'contractTemplate',
    component: () => import('../views/enterpriseInformation/contractTemplate.vue'),
    meta: {
      parentPath: '/enterpriseInformation/contractTemplate',
      requireAuth: true,
      keepAlive: true,
      name: '合同模板管理'
    }
  },
  {
    path: '/enterpriseInformation/bankAccountManagement',
    name: 'bankAccountManagement',
    component: () => import('../views/enterpriseInformation/bankAccountManagement.vue'),
    meta: {
      parentPath: '/enterpriseInformation/bankAccountManagement',
      requireAuth: true,
      keepAlive: true,
      title: '银行开户管理'
    }
  },
  {
    path: '/enterpriseInformation/bankAccountDetails',
    name: 'bankAccountDetails',
    component: () => import('../views/enterpriseInformation/children/bankAccountDetails.vue'),
    meta: {
      parentPath: '/enterpriseInformation/bankAccountManagement',
      requireAuth: true,
      title: '银行开户详情'
    }
  },
  {
    path: '/enterpriseInformation/addTopUp',
    name: 'addTopUp',
    component: () => import('../views/enterpriseInformation/children/addTopUp.vue'),
    meta: {
      parentPath: '/enterpriseInformation/accountInformation',
      requireAuth: true,
      keepAlive: true,
      title: '充值新增'
    }
  },
  {
    path: '/enterpriseInformation/addWithdrawDeposit',
    name: 'addWithdrawDeposit',
    component: () => import('../views/enterpriseInformation/children/addWithdrawDeposit.vue'),
    meta: {
      parentPath: '/enterpriseInformation/accountInformation',
      requireAuth: true,
      title: '提现新增'
    }
  },
  {
    path: '/enterpriseInformation/fundDetails',
    name: 'fundDetails',
    component: () => import('../views/enterpriseInformation/children/fundDetails.vue'),
    meta: {
      title: '资金明细',
      parentPath: '/enterpriseInformation/accountInformation',
      keepAlive: true,
      requireAuth: true
    }
  }
]
export default enterpriseInformationRoute
