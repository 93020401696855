// 双层加密   先MD5  再jsencrypt
import md5 from 'js-md5'
import JSEncrypt from 'jsencrypt'
// 数据转为树结构的方法
// pid是子元素的属性名  ID是父元素上的属性名
function translateDataToTree(data, pid, id) {
  // 没有父节点的数据
  let parents = []; let children = []
  parents = data.filter(
    value => value[pid] === 'undefined' || value[pid] == null || value[pid] === ''
  )

  // 有父节点的数据
  children = data.filter(
    value => value[pid] !== 'undefined' && value[pid] != null
  )
  // 定义转换方法的具体实现
  const translator = (parents, children) => {
    // 遍历父节点数据
    parents.forEach(parent => {
      // 遍历子节点数据
      children.forEach((current, index) => {
        // 此时找到父节点对应的一个子节点
        if (current[pid] === parent[id]) {
          // 对子节点数据进行深复制，这里只支持部分类型的数据深复制，对深复制不了解的童靴可以先去了解下深复制
          const temp = JSON.parse(JSON.stringify(children))
          // 让当前子节点从temp中移除，temp作为新的子节点数据，这里是为了让递归时，子节点的遍历次数更少，如果父子关系的层级越多，越有利
          temp.splice(index, 1)
          // 让当前子节点作为唯一的父节点，去递归查找其对应的子节点
          translator([current], temp)
          // 把找到子节点放入父节点的children属性中
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current])
        }
      })
    })
  }
  // 调用转换方法
  translator(parents, children)
  // 返回最终的结果
  return parents
}
// 加密的方法
function encrypt(plaintext) {
  const key =
    'MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBANLpJwrVTmdburKp9khRHBUESLp4KVE+S3umgO7ZaOGtnP0CLvvH8JkpMFJSivTGcpTOAzumevweLhOaeRh3k/69xsSKk2hc/TYGuESWltPta8BQ5xFZxne+e0EOkqWYfrSVEyUaWlgik6EbP2dj5w2fZkZI5QAmAa4vGL/VL89rAgMBAAECgYEAjfofqx4+YjuQqXbacIIRSmCQ6N28ypEJ1RoXzzGRdeIePP88S4ejgicE/VXNbsssFNm9uWjvf0p9r/qbrAnLGEYSoYXm9qOzA6rmU9p1eLW2HaRYCM2jAy9E3wkKCBGzklJm8su9GqXHPRmKCHYMaxSwyvRXJlbsWTsqjRfUtAkCQQD8eWy6DDMtyQowDYGhM+E5f91FHCFuFCVMPxMNahWznJBXTHqi+7GLDx1I6E40WRTJAiCK+VeMwzA/KHfiQ1j9AkEA1dskM6jX2V2eQHxtkoF4NKaTp+xmmzUp3a8uw+SBvKYv+YFN7/sCa8zksyrFarDtUv0WOXvV6uzqfIF24poKhwJAXg5iWgE3g8QUnog5lbN9IW2oIhnzvk2VA42v7FxsD0ixYURln5hCsTqFP2cD026oVqYLN5riaddBfxX1a36JhQJBALuUzctWZYavHFl6Cu6AC/06w7d+n32ygzeFKuL+82GS7+/NoJxIMxZ1kYEqmiF8cZ7iifUAD2JST1fIg4w+C88CQQDTNue1HPVHfpUL0kgz5ivFvl5HRZO/1x0/SNGccGRNWwpt3ryXq72BcgvcCZdwZ0eBLdq4+F61MCYEmg+t7RRY'
  const thisKeyPair = new JSEncrypt({ default_key_size: 1024 })
  if (plaintext instanceof String) {
    // 1、JSON.stringify
    plaintext = JSON.stringify(plaintext)
  }
  thisKeyPair.setPublicKey(key)
  return thisKeyPair.encrypt(md5(plaintext))
}
const timestampToTime = timestamp => {
  const date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-'
  const M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':'
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}

// 金额 千位逗号分隔符过滤器
function numberToCurrency(value) {
  if (!value) return 0
  // 获取整数部分
  const intPart = Math.trunc(value)
  // 整数部分处理 添加（,）
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  let floatPart = ''
  // 将数值截取为小数部分和整数部分
  const valueArray = value.toString().split('.')
  // 有小数 获取小数部分
  if (valueArray.length === 2) {
    floatPart = valueArray[1].length < 2 ? valueArray[1].toString() + '0' : valueArray[1].toString()
    return intPartFormat + '.' + floatPart
  }
  return intPartFormat + '.' + '00'
}
// 金额向上取整
function roundUpToInteger(money, num = 2) {
  if (!money || !Number(money)) return 0.00
  let digit = 1
  for (let index = 0; index < num; index++) {
    digit += '0'
  }
  return Math.round((money + Number.EPSILON) * Number(digit)) / Number(digit)
}
function downloadFile(url, name = '购销合同.pdf') {
  if (!url) return
  fetch(url, {
    method: 'get',
    mode: 'cors'
  })
    .then((response) => response.blob())
    .then((res) => {
      const downloadUrl = window.URL.createObjectURL(
        // new Blob() 对后端返回文件流类型处理
        new Blob([res], {
          type: 'application/pdf'
        })
      )
      // word文档为msword,pdf文档为pdf
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
// 金额自动补齐N位小数以及加上千分位
function thousandsFractionsSmaller(money, num = 2) {
  if (!money || !Number(money)) return '—'
  let digit = 1
  for (let index = 0; index < num; index++) {
    digit += '0'
  }
  money = (Math.round((money + Number.EPSILON) * Number(digit)) / Number(digit)).toFixed(num)
  var numArry = money.split('.')
  var l = numArry[0].split('').reverse()
  var t = ''
  for (var i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '')
  }
  return t.split('').reverse().join('') + '.' + numArry[1]
}
// 减法精度处理
function subPrecision(arg1, arg2) {
  let r1, r2
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = Math.pow(10, Math.max(r1, r2))
  // 动态控制精度长度
  // const n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m)
}
export { thousandsFractionsSmaller, downloadFile, roundUpToInteger, encrypt, timestampToTime, translateDataToTree, numberToCurrency, subPrecision }
