import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import { Message } from 'element-ui'
import warehousingSystemRouter from './warehousingSystem'
import enterpriseInformationRoute from './enterpriseInformation'
// 需要登录的加上这个参数
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/index.vue'),
    redirect: '/homePage/index',
    meta: {
      title: '首页',
      parentPath: '/enterpriseInformation/addInformation',
      requireAuth: true,
      keepAlive: true
    },
    children: [
      ...warehousingSystemRouter,
      ...enterpriseInformationRoute,
      {
        path: '/homePage/index',
        name: 'homePage',
        component: () => import('@/views/homePage/index.vue'),
        meta: {
          name: '首页',
          parentPath: '/homePage/index',
          requireAuth: true,
          keepAlive: true
        }
      },
      {
        path: '/invoiceManagement/lookedUpManagement',
        name: 'lookedUpManagement',
        meta: {
          title: '抬头管理',
          keepAlive: true,
          parentPath: '/invoiceManagement/lookedUpManagement',
          requireAuth: true
        },
        component: () =>
          import('@/views/invoiceManagement/lookedUpManagement.vue')
      },
      {
        path: '/institutionalFramework/organizationalManagement',
        name: 'organizationalManagement',
        meta: {
          title: '组织管理',
          keepAlive: true,
          parentPath: '/institutionalFramework/organizationalManagement',
          requireAuth: true
        },
        component: () =>
          import('@/views/institutionalFramework/organizationalManagement.vue')
      },
      {
        path: '/institutionalFramework/permission',
        name: 'permission',
        meta: {
          title: '权限管理',
          parentPath: '/institutionalFramework/permission',
          keepAlive: true,
          requireAuth: true
        },
        component: () =>
          import('@/views/institutionalFramework/permission.vue')
      },
      {
        path: '/institutionalFramework/role',
        name: 'role',
        meta: {
          title: '角色管理',
          parentPath: '/institutionalFramework/role',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/institutionalFramework/role.vue')
      },
      {
        path: '/institutionalFramework/user',
        name: 'user',
        meta: {
          title: '用户管理',
          parentPath: '/institutionalFramework/user',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/institutionalFramework/user.vue')
      },
      {
        path: '/inventoryManagement/index',
        name: 'inventoryManagementIndex',
        meta: {
          title: '库存管理',
          parentPath: '/inventoryManagement/index',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/inventoryManagement/index.vue')
      },
      {
        path: '/personalCertificate',
        name: 'personalCertificate',
        meta: {
          title: '个人中心'
        },
        component: () => import('@/views/login/personalCertificate.vue')
      }
    ]
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册'
    },
    component: () => import('@/views/login/register.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '*',
    name: '404',
    meta: {
      title: '404'
    },
    component: () => import('@/views/wrongIndication/404.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (store.state.token || sessionStorage.getItem('tokenWms')) {
      // 通过vuex state获取当前的token是否存在
      // 这里在判断一下  在登录的情况下  如果信息没有补入的话  就一直跳到信息补入的页面
      const userInfo = JSON.parse(localStorage.getItem('userInfoWms'))
      if (to.path !== '/enterpriseInformation/addInformation' && from.path !== '/login' && userInfo && ['01', '-1'].includes(userInfo.cmpState)) {
        Message({
          message: '请补入信息！',
          type: 'error'
        })
        next({
          path: '/enterpriseInformation/addInformation'
        })
        return
      }
      next()
    } else {
      Message({
        message: '非法查看，请先登录',
        type: 'error'
      })
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next()
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default router
